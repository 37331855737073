<template>
    <div v-if="state.loading.configurations" class="absolute top-50 left-50">
        <ProgressSpinner />
    </div>
    <div v-else>
        <h1 class="text-center">{{ state.client.name }}</h1>
        <TabMenu :activeIndex="state.active" :model="state.items" @tab-change="goTo($event.index)" exact />
        <router-view :configurations="state.configurations" :client="state.client"
            @realoadConfigurations="reloadConfigurations($event)" @goToRegisterClient="goTo(1)"></router-view>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useNotificationToast from '../../../composables/useNotificationToast'
import services from '../../../services'
import TabMenu from 'primevue/tabmenu'
import ProgressSpinner from 'primevue/progressspinner'

export default {
    components: { TabMenu, ProgressSpinner },

    setup() {

        const route = useRoute()

        const state = reactive({

            client: {},
            configurations: [],
            active: route.meta.tabMenuIndex,
            loading: {
                configurations: false,
            },
            items:
                [
                    {
                        label: 'Fornecedores',
                        icon: 'pi pi-fw pi-dollar',
                        routeName: 'client-configurations'
                    },
                    {
                        label: 'Registrar Fornecedores',
                        icon: 'pi pi-fw pi-plus',
                        routeName: 'register-configuration'
                    },
                    {
                        label: 'Gerenciar Endereços',
                        icon: 'pi pi pi-desktop',
                        routeName: 'manage-adresses'
                    }
                ]
        })
        const { success } = useNotificationToast()
        const router = useRouter()
        const store = useStore()

        function reloadConfigurations(event) {

            if (event) {
                state.active = event.tabMenuIndex
                success(event.successMessage)
            }

            loadConfigurations()
        }

        loadConfigurations()

        function loadConfigurations() {

            state.loading.configurations = true

            const token = store.getters.getToken

            services.configurations.getConfigurationsClient({
                id: route.params.id,
                token
            }).then(({ data }) => {

                state.client = data.client
                state.configurations = data.configurations
                route.meta.readableName = `Configurações - ${state.client.name}`
                store.commit('updateStoredRoutes', {
                    currentRoute: {
                        fullPath: route.fullPath,
                        meta: route.meta
                    },
                    previousRoute: {
                        fullPath: '/clients',
                        meta: {
                            requiresAuth: true,
                            readableName: 'Clientes',
                            icon: 'pi pi-thumbs-up'
                        }
                    }
                })

                state.loading.configurations = false

            })



        }

        function goTo(activeIndex) {

            state.active = activeIndex

            router.push({
                name: state.items[activeIndex].routeName
            })

        }

        return {
            state,
            reloadConfigurations,
            loadConfigurations,
            goTo
        }

    }
}
</script>